.form-container {
  background-color: #f8f9fa; /* Light grey background */
  max-width: 700px; /* Limit the width of the form */
  width: 100%; /* Ensure the form takes up the full width of its container */
}

h2 {
  font-weight: bold;
  color: #333;
}

.text-danger {
  font-size: 0.875rem; /* Slightly smaller font for error messages */
}

.form-control.is-invalid {
  border-color: #dc3545; /* Red border */
  box-shadow: none; /* Remove the default Bootstrap shadow */
}

.form-error {
  color: #dc3545; /* Red text for error messages */
  font-size: 0.875rem;
}
