.footer {
  &__area {
    background: linear-gradient(45deg, #701eca, black);

    &__top {
      @media (max-width: 768px) {
        text-align: center;
        .row {
          justify-content: center;
        }
        .col-sm-12,
        .col-sm-6 {
          margin-bottom: 20px;
        }
      }
    }
  }

  &__widget {
    text-align: center;
    @media (max-width: 768px) {
      text-align: center;
      margin-bottom: 20px;
    }

    &-title {
      h4 {
        font-size: 24px;
        color: white;
        font-weight: 900;
        margin-bottom: 25px;
        text-align: center;
      }
    }

    &-content {
      @media (max-width: 768px) {
        text-align: center;
      }
      display: flex;
      justify-content: center;
      // align-items: center;
      &-2 {
        p {
          color: #d1cfdd;
        }
      }
    }
  }

  &__links {
    ul {
      li {
        margin-bottom: 5px;
        color: white;

        a {
          position: relative;
          text-decoration: none;

          &::before {
            content: '';
            width: 0;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: auto;
            right: 0;
            transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
            background: white;
          }

          &:hover {
            color: grey;

            &::before {
              width: 100%;
              left: 0;
              right: auto;
            }
          }
        }
      }
    }
  }

  &__info {
    ul {
      li {
        margin-bottom: 8px;
        display: flex;
        color: white;

        .icon {
          i {
            line-height: 27px;
            margin-right: 15px;
            color: white;
          }
        }

        .text {
          span {
            font-weight: 700;
            color: white;
          }
        }
      }
    }
  }

  &__copyright {
    border-top: 1px solid #3f395c;
    padding: 25px 0;
    color: white;
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
    }

    &-text {
      p {
        margin-bottom: 0;
        color: white;

        a {
          font-weight: 700;
          color: #ffffff;
          &:hover {
            color: #d1cfdd;
          }
        }
      }
    }
  }

  &__social {
    @media (max-width: 768px) {
      margin-top: 20px;
      text-align: center;
    }

    ul {
      li {
        display: inline-block;
        margin-right: 7px;
        color: white;

        a {
          position: relative;
          display: inline-block;
          width: 40px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #363054;
          font-size: 14px;
          border-radius: 10px;
          color: #ffffff;

          &:hover {
            background: #5c1fc4;
            color: white;
          }
        }
      }
    }
  }

  &__subscribe {
    input {
      width: 100%;
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      background: white;
      border: none;
      box-shadow: 0px 2px 4px 0px rgba(2, 0, 40, 0.08);
      border-radius: 4px;

      &::placeholder {
        font-weight: 500;
        color: #686777;
      }

      &:focus {
        border: none;
        outline: none;

        &::placeholder {
          opacity: 0;
        }
      }
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 15px;
      font-weight: 700;
      color: white;
      background: #5c1fc4;
      height: 50px;
      line-height: 50px;
      text-align: center;
      padding: 0 15px;
      border-radius: 0 4px 4px 0;
    }
  }
}
