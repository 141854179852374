.capabilities {
  &__content {
    @media #{$md} {
      position: relative;
    }
    @media #{$sm} {
      position: relative;
    }
    @media #{$xs} {
      position: relative;
    }
  }
  &__icon {
    margin-right: 20px;
    flex-shrink: 0; /* Prevent the icon from shrinking */
    font-size: 18px; /* Adjust icon size if needed */
    color: $theme-color; /* Adjust color if needed */
  }
  &__thumb {
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    @include background();
    @media #{$md} {
      display: none;
    }
    @media #{$sm} {
      display: none;
    }
    @media #{$xs} {
      display: none;
    }
  }
  &__shape {
    left: 0;
    top: -165px;
  }
  &__list {
    & ol {
      padding-left: 0;
      list-style-type: none;

      & li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 25px;
        font-size: 22px;
        color: $black-soft;
        font-family: $frank;
        line-height: 1.5; /* Improves readability */

        &:hover {
          color: $white;
          cursor: default;
        }

        /* Icon placement */
        .capabilities__icon {
          margin-right: 15px;
          margin-top: 5px; /* Align icon vertically with text */
        }

        /* Ensure text lines break correctly */
        span {
          flex: 1; /* Take up remaining space */
        }
      }
    }
  }
}
